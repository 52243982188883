
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useVehicleTypeGroup } from '@/composable/useVehicleTypeGroup'
import { VehicleTypeGroup } from '@/types/vehicle-type-group'
import {
  required as requiredRule,
  booleanTrueRequired,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  name: 'MasterVehicleTypeGroups',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateVehicleTypeGroups,
      data: vehicleTypeGroups,
      add,
      update,
      remove,
    } = useVehicleTypeGroup(true)

    function createFormModel(): Partial<VehicleTypeGroup> {
      return {
        uuid: uuidv4(),
        name: '',
        customerAvailabilityLevel1: 0,
        customerAvailabilityLevel2: 0,
        customerAvailabilityLevel3: 0,
        customerAvailabilityLevel4: 0,
      }
    }
    const model = ref<Partial<VehicleTypeGroup>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'name',
        label: 'Fordonstyp grupp',
        align: 'left',
        field: 'name',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel1',
        label: 'Kundtillgänglighet nivå 1',
        align: 'left',
        field: 'customerAvailabilityLevel1',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel2',
        label: 'Kundtillgänglighet nivå 2',
        align: 'left',
        field: 'customerAvailabilityLevel2',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel3',
        label: 'Kundtillgänglighet nivå 3',
        align: 'left',
        field: 'customerAvailabilityLevel3',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel4',
        label: 'Kundtillgänglighet nivå 4',
        align: 'left',
        field: 'customerAvailabilityLevel4',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateVehicleTypeGroups.value,
      }
    })

    const exportData = computed(() => {
      return vehicleTypeGroups.value.map((vehicleTypeGroup) => {
        return {
          'Fordonstyp grupp': vehicleTypeGroup.name,
          'Kundtillgänglighet nivå 1':
            vehicleTypeGroup.customerAvailabilityLevel1,
          'Kundtillgänglighet nivå 2':
            vehicleTypeGroup.customerAvailabilityLevel2,
          'Kundtillgänglighet nivå 3':
            vehicleTypeGroup.customerAvailabilityLevel3,
          'Kundtillgänglighet nivå 4':
            vehicleTypeGroup.customerAvailabilityLevel4,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      requiredNotNegative,
      booleanTrueRequired,
      vehicleTypeGroups,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
